.button {
    display: flex;  
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 24px;
    background: rgba(255, 83, 83, 1);
    cursor: pointer;
    user-select: none;
}