@import "~@/styles/viewport.scss";

.modal {
  box-sizing: border-box;

  .returnImg {
    position: absolute;
    left: vm(20);
    top: vm(20);
    cursor: pointer;
  }

  .closeImg {
    position: absolute;
    right: vm(20);
    top: vm(20);
    cursor: pointer;
  }

  .content {
    padding: vm(20) 0;
  }

  :global {
    .rc-dialog-close {
      display: none;
    }

    .rc-dialog-body {
      position: relative;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 vm(30) white inset !important;
    }
  }
}
