@import "~@/styles/viewport.scss";
@import '~@/styles/mediaQuery.scss';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.loadContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;
}
.loadContainer {
  position: fixed;
  top: 50%;
  z-index: 99999999998;
  left: 0;
  right: 0;
  text-align: center;
  width: vm(100);
  min-height: vm(100);
  background: rgba(0, 0, 0, 0.85);
  border-radius: vm(12);
  transform: translateY(-50%);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 vm(2) vm(2) vm(2);
  .content {
    margin-top: vm(5);
    color: #ddd;
  }
  @include mediaL {
    width: 100px;
    min-height: 100px;
    border-radius: 12px;
    font-size: vwPC(18);
    padding: 0 vwPC(2) vwPC(2) vwPC(2);
  }
}

.loading {
  width: vm(35);
  height: vm(35);
  margin: vm(18) auto 0;
  border: solid vm(3) #ddd;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @include mediaL {
    margin: 15px auto 0;
    width: 40px;
    height: 40px;
  }
}

.loadContent-l {
  width: vm(200);
  @include mediaL {
    width: 200px;
  }
}
.contentSupplement {
  color: #ddd;
  font-size: vm(12);
  margin: vm(10) auto;
  padding: 0 vm(10);
  @include mediaL {
    font-size: 12px;
    margin: 10px auto;
    padding: 0 10px;
  }
}
