@font-face {
  font-family: 'FZLanTYJW';
  src: url('./fonts/FZLanTYJW_Zhong.woff2') format('woff2'), url('./fonts/FZLanTYJW_Zhong.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'FZYanSJW';
  src: url('./fonts/FZLanTYJW_ZhongCu.woff2') format('woff2'), url('./fonts/FZLanTYJW_ZhongCu.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: optional;
}
