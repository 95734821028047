@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/config.scss';
@import '~@/styles/color.scss';
@import '~@/styles/font-HelveticaNeueLTPro.scss';

.body-width {
}

.component-wrapper {
  @include mediaS {
    //min-width: vm(375);
  }
  @include mediaL {
    max-width: $pc-max-width;
    min-width: $pc-min-width;
  }
}

.show-pc {
  @include mediaL {
    display: block;
  }
}

.hide-pc {
  @include mediaL {
    display: none;
  }
}

.show-mobile {
  @include mediaS {
    display: block;
  }
}

.hide-mobile {
  @include mediaS {
    display: none;
  }
}

body {
  @extend .body-width;
  background: #f2f2f2;
}

p {
  line-height: 1.4;
}
@include mediaS {
  #udesk_btn {
    // width: 56px;
    // height: 56px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // position: fixed;
    // left: 19px;
    // bottom: 30px;
    // z-index: 1000000000;
    // background-color: rgb(255 255 255);
    // border-radius: 56px;
    // box-shadow: 0 0 20px rgb(86 97 115 / 20%);
  }
  #udesk_btn > a {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    right: 22px !important;
    bottom: 103px !important;
    margin: 0 !important;
    overflow: hidden;
    border: 6px solid #fff;
    &::after {
    }
  }
  #udesk_btn > a > span {
    width: 30px !important;
    height: 30px !important;
    margin: 8px 9px 10px !important;
  }
}
@include mediaL {
  #udesk_btn > a {
    right: 0 !important;
    bottom: 103px !important;
  }
}

body.locale-en {
  font-family: HelveticaNeueLTPro, sans-serif;
  font-weight: normal;
  @include mediaL {
    letter-spacing: 0.03em;
  }
}

.rc-dialog-wrap {
  z-index: 9999999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-dialog-mask {
  z-index: 9999999999 !important;
}

.rc-drawer {
  z-index: 9999999999 !important;
}

.rc-drawer-content-wrapper {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  box-sizing: border-box !important;
  overflow: visible !important;
  width: 100vw !important;
  overflow-x: hidden;
}

.vc-switch, .vc-mask, .vc-panel {
  // bottom: 50% !important;
  z-index: 9999999999 !important;
}