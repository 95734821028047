@import "~@/styles/mediaQuery.scss";
@import '~@/styles/viewport.scss';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.loadContent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10002222222222312313;
}
.loadContainer {
  position: fixed;
  top: 50%;
  z-index: 10001;
  left: 0;
  right: 0;
  text-align: center;
  width: vm(100);
  min-height: vm(100);
  background: rgba(0, 0, 0, 0.85);
  border-radius: vm(12);
  transform: translateY(-50%);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 vm(2) vm(2) vm(2);
  .content {
    margin-top: vm(5);
    color: #ddd;
  }
  @include mediaL {
    width: 100px;
    min-height: 100px;
    border-radius: 12px;
    font-size: vmPC(18);
    padding: 0 vmPC(2) vmPC(2) vmPC(2);
  }
}

.loading {
  width: vm(20);
  height: vm(20);
  margin: vm(18) auto 0;
  border: solid vm(2) #ddd;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @include mediaL {
    margin: 15px auto 0;
    width: 20px;
    height: 20px;
  }
  @include mediaS {
    margin: vm(24) auto 0;
  }
}
