@import "~@/styles/viewport.scss";

.register {
  .title {
    color: #1b1f4d;
    text-align: center;
    font-family: PingFang SC;
    font-size: vm(22);
    font-style: normal;
    font-weight: 600;
    margin-bottom: vm(16);
  }

  .loginBtn {
    border-radius: vm(28);
    border: 1px solid #dfe0ed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: vm(48);
    font-weight: 500;
    margin-top: vm(12);
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: vm(28.8);
      height: vm(28.8);
      background-size: vm(28.8) vm(28.8);
      position: absolute;
      left: vm(11.6);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .phoneBtn {
    &::before {
      background-image: url('/images/login/phone.svg');
    }
  }

  .googleBtn {
    &::before {
      background-image: url('/images/login/google.svg');
    }
  }

  .appleBtn {
    &::before {
        background-image: url('/images/login/apple.svg');
    }
  }

  .googlContent {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    opacity: 0;
}

  .facebookBtn {
    &::before {
      background-image: url('/images/login/facebook.svg');
    }
  }

  .wechatBtn {
    &::before {
      background-image: url('/images/login/wechat.svg');
    }
  }

  .login {
    text-align: center;
    margin: vm(28) 0 vm(16) 0;
    font-size: vm(16);
  }

  .privacy {
    margin-top: vm(90);
    font-size: vm(14);
    color: #8d8fa6;
    text-align: center;

    .highlight {
      color: #ff5353;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
