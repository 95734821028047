@import '~@/styles/viewport.scss';
@import '~@/styles/config.scss';
@import '~@/styles/mediaQuery.scss';

.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  max-width: $pc-max-width;
  margin: 0 auto;
  background: #ffffff;
  @include mediaS {
    min-width: vm(320);
  }
}

.header {
  flex: 0 0 auto;
}

.content {
  flex: 1 0 auto;
  // 内容不被nav遮挡
  margin-top: 80px;
  @include mediaS {
    margin-top: vm(46);
  }
}

.footer {
  flex: 0 0 auto;
}
