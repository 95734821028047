@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";

.input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #DFE0ED;
  box-sizing: border-box;
  // background: #F7F7F7;
  border-radius: vm(50);
  // margin-bottom: vm(8);
  height: vm(48);
}

.input {
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
  text-align: left;
  box-sizing: border-box;
  border-radius: vm(50);
  font-size: vm(14);
  padding: 0 vm(16);
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  color: #484C70;
  &::placeholder {
    color: #c2c2c2;
    text-align: left;
    font-weight: 400;
  }
}
.inputPositionT {
  font-size: vm(16);
  @include mediaXL {
    font-size: vm(20);
  }
}

.panel {
  top: 10px !important;
} 

:global {
  .wk-form-item-error {
    :local {
      .input {
        border: 1px solid #FF5353;
        // background: transparent;
      }
    }
  }
}
