.privacyConfirmTip {
    text-align: center;

    .title {
        color: #1B1F4D;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 160% */
    }

    .description {
        // margin-top: 12px;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }

    .highlight {
        color: rgb(255, 83, 83);
        cursor: pointer;
    }
}