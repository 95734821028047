@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";
@import "~@/styles/config.scss";
@import "~@/styles/color.scss";
@import "~@/styles/font-HelveticaNeueLTPro.scss";
@import "~@/styles/font-FZLanTYJW.scss";

.container {
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 12;
  @include mediaL {
    width: 320px;
    height: 100%;
    border: 10px solid #FFB3B3;
    box-shadow: 0px 2.10526px 21.0526px #FC2D00;
    border-radius: 28px;
    padding: 46px 24px 0 24px;
    right: -4%;
    top: 22%;
  }
  @include mediaXL {
    width: 440px;
    height: 100%;
    border: 10px solid #FFB3B3;
    box-shadow: 0px 2.10526px 21.0526px #FC2D00;
    border-radius: 28px;
    padding: 66px 32px 0 32px;
    right: 16%;
    top: 22%;
  }
  @include mediaS {
    display: none;
  }
  :global {
    .select__placeholder {
      color: #c2c2c2;
      font-weight: 400;
      min-width: 200px;
    }
    .select__control {
      background: #f7f7f7;
      height: 44px;
      border-radius: 50px;
      border: none;
      padding: 0 8px;
      margin-bottom: 8px;
      box-shadow: none;
      font-size: 14px;
      line-height: 42px;
      @include mediaXL {
        background: #f7f7f7;
        height: 56px;
        border-radius: 50px;
        border: none;
        padding: 0 12px;
        margin-bottom: 12px;
        box-shadow: none;
        font-size: 18px;
      }
      @include mediaS {
        height: 52px;
        width: calc(100% - 44px);
        margin-left: 22px;
        margin-bottom: 10px;
        font-size: 15px;
        padding: 0 13px;
      }
    }
    .select__indicator-separator {
      display: none;
    }
    .select__menu {
      position: absolute;
      background: #ffffff;
      box-sizing: border-box;
      z-index: 100;
      max-height: 220px;
      border: 0.5px solid #E7E7E7;
      box-shadow: 0px 3px 14px 2px rgb(0 0 0 / 5%);
      border-radius: 12px;
      padding: 4px;
      transition: select-m 1s;
      // opacity: .5;
      @include mediaS {
        width: calc(100% - 44px);
        margin-left: 22px;
      }
    }
    .select__menu-list {
      z-index: 100;
      max-height: 200px;
      display: block !important;
      overflow-y: auto;
    }
    .select__option {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:active {
        background: #f9f9f9;
        cursor: pointer;
      }
      &:hover {
        color: #FC2D00;
      }
      &--is-focused {
        background: transparent;
        cursor: pointer;
      }
      &--is-selected {
        background: transparent;
        color: #FC2D00;
      }
      &:first-child {
        margin-top: 0px;
      }
    }
  }
}
.containerMobile {
  display: none;
  box-shadow:none !important;
}
.containerForm {
  height: 552px;
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;
  animation: slide-bottom 1.5s;
  box-shadow: 0px -6px 8px rgba(255, 71, 41, 0.25), inset 0px 1px 0px #FFF8EE;
  border-radius: 22px 22px 0 0;
}
.containerPcForm {
  height: inherit;
  display: block;
  position: fixed;
  left: -4%;
  width: 380px;
  animation: slide-bottom 1.5s;
  box-shadow: none;
  border-radius: 22px;
  margin: auto;
  border: none;
  padding: 30px;
  padding-top: 56px;
  @include mediaXL {
    display: block;
    position: fixed;
    left: 16%;
    width: 520px;
    animation: slide-bottom 1.5s;
    box-shadow: none;
    border-radius: 22px;
    border: none;
    margin: auto;
    padding-top: 80px;
  }
}
.sectionForm {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.sectionPcForm {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 300000000000444;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

}
.sectionMobile {
  @include mediaS {
    position: fixed;
    bottom: 0;
    z-index: 2001;
    width: 100%;
  }
}
// 移动端 导航打开的表单 和 首页表单问题
// 默认情况 首页的移动端吸底按钮 层级更改 这样换起弹窗 nav遮挡住
// 点击展开nav 层级比 移动端吸底更高 遮挡住按钮
// nav的form层级 比 移动端吸底更高 这样遮挡住移动端的按钮
.sectionMobileHighZIndex{
  z-index: 2010;
}
.closeImg {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 94%;
  top: -15%;
  cursor: pointer;
  @include mediaXL {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    left: 93%;
    top: -17%;
    cursor: pointer;
  }
  @include mediaS {
    display: block;
    width: 12px;
    height: 12px;
    position: relative;
    left: 16px;
    top: 4px;
  }
}
.closeImgPc {
  @include mediaS {
    display: none !important;
  }
}
.closeImgPcNone {
  display: none !important;
  @include mediaS {
    display: block !important;
  }
}
.closeImgM {
  display: none !important;
  @include mediaS {
    display: block !important;
  }
}
.formTitle {
  text-align: center;
  line-height: 50px;
  color: #660000;
  font-size: 22px;
  font-weight: 800;
  @include mediaL {
    width: 270px;
    height: 48px;
    background-image: url('./assets/form-top-bg.png');
    background-size: 100% 100%;
    position: absolute;
    top: -13px;
    left: 20px;
    font-size: 16px;
    line-height: 47px;
  }
  @include mediaXL {
    width: 380px;
    height: 64px;
    background-image: url('./assets/form-top-bg.png');
    background-size: 100% 100%;
    position: absolute;
    top: -16px;
    left: 20px;
    line-height: 58px;
    font-size: 22px;
  }
  @include mediaS {
    padding-bottom: 12px;
    .formTitle1 {
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }
    .formTitle2 {
      font-size: 20px;
      line-height: 28px;
      color: #FF5353;
    }
  }
}
.formTitlePc {
  width: 310px;
  height: 48px;
  top: -5px;
  left: 34px;
  line-height: 40px;
  font-weight: 800;
  @include mediaXL {
    width: 412px;
    height: 64px;
    left: 11%;
    top: -7px;
    line-height: 56px;
  }
}
.formRadio {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px 0 18px 0;
  font-weight: 400;
  margin: auto;
  @include mediaS {
    width: calc(100% - 44px);
    padding: 8px 0 18px;
    justify-content: center;
  }
  span {
    flex: 1;
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
  }
  a {
    color: rgb(255, 83, 83);
    text-decoration-line: none;
  }
  input {
    display: none;
  }
  label {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    border: 1px solid rgb(204, 204, 204, 0.7);
    position: relative;
    cursor: pointer;
    margin-right: 9px;
    @include mediaXL {
      width: 18px;
      height: 18px;
    }
  }
  label::before {
    display: inline-block;
    content: " ";
    border: 2px solid #fff;
    width: 4.5px;
    height: 2.5px;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    top: 4px;
    left: 4px;
    position: absolute;
    opacity: 0;
    @include mediaXL {
      width: 7.5px;
      height: 4.5px;
    }
  }
  input:checked+label {
    background: #FF5353;
    border: 1px solid #FF5353;
  }
  input:checked+label::before{
    opacity: 1;
    transform: all 0.5s;
  }
}
.formRadioPosition{
  justify-content: center;
  label {
    width: 14px;
    height: 14px;
    position: relative;
    @include mediaXL {
      width: 18px;
      height: 18px;
    }
  }
}
.formButton {
  width: calc(100% + 24px);
  height: 64px;
  line-height: 42px;
  position: relative;
  right: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #fff;
  border: none;
  background-image: url('./assets/btn-bg.png');
  background-size: 100% 100%;
  bottom: 10px;
  cursor: pointer;
  padding-bottom: 5px;
  @include mediaXL {
    height: 89px;
    line-height: 78px;
    font-size: 20px;
    padding-bottom: 8px;
  }
}
.formButtonMobile {
  width: calc(100% - 48px);
  margin: 0 0 13px 24px;
  height: 57px;
  line-height: 46px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background-color: #fff;
  border: none;
  background-image: url('./assets/btn-bg-m.png');
  background-size: 100% 100%;
  padding-bottom: 5px;
}
.formButtonPc {
  margin: 0 0 0px 24px;
  width: 100%;
  height: 56px;
  font-size: 16px;
  margin-left: 0;
  font-weight: 600;
  color: #fff;
  background-color: #fff;
  border: none;
  background-image: url('./assets/btn-bg-m.png');
  background-size: 100% 100%;
  @include mediaXL {
    width: calc(100% + 24px);
    height: 105px;
    font-size: 20px;
    margin-left: -12px;
    background-image: url('./assets/btn-bg.png');
  }
}
.submitBtnHand {
  width: 60px;
  height: 60px;
  position: absolute;
  right: -14px;
  bottom: -10px;
  animation: btn-zoom 1s infinite;
  @include mediaXL {
    width: 85px;
    height: 84px;
    bottom: -5px;
  }
}
body .select {
  > div {
    cursor: pointer;
    &:hover,
    &:active {
      &:last-child {
        span {
          display: none;
        }
      }
    }
    &:last-child {
      span {
        display: none;
      }
    }
  }
}
.errorSelect {
  :global {
    .select__control {
      border: 1px solid #FF5353 !important;
    }
  }
}
.errorInput, .errorPhone {
  border: 1px solid #FF5353 !important;
}
.formError {
  font-size: 10px;
  color: #FF5353;
  padding: 0px 16px 0 16px;
  position: relative;
  bottom: 8px;
  @include mediaXL {
    padding: 0px 20px 0 20px;
  }
  @include mediaS {
    padding: 22px 20px 22px 43px;
  }
}
.positionPhoneError{
  position: absolute;
  top: -67px;
  left: 16%;
  width: 228px;
  height: 64px;
  line-height: 52px;
  text-align: center;
  border-radius: 4px;
  font-size: 18px;
  color: #fff;
  background-image: url('./assets//error-bg.png');
  background-size: 100% 100%;
}
.positionBoxWkImg {
  width: 112px;
  height: 94px;
  background-image: url('./assets/wk.png');
  background-size: 100% 100%;
  position: relative;
  bottom: 22px;
  left: 208px;
  @include mediaXL {
    width: 132px;
    height: 112px;
    bottom: 20px;
    left: 18%;
  }
  @include mediaS {
    display: none;
  }
}
.positionBoxSlogan {
  width: 211px;
  height: 40px;
  background-image: url('./assets/slogan-zh.svg');
  background-size: 100% 100%;
  position: relative;
  bottom: 76px;
  left: 328px;
  @include mediaXL {
    width: 302px;
    height: 46px;
    bottom: 85px;
    left: 23.5%;
  }
  @include mediaS {
    display: none;
  }
}
.pcBtnPosition {
  position: fixed;
  width: 100%;
  height: 72px;
  background-image: url('./assets/position-bottom-form.png');
  background-size: 100% 100%;
  left: 0;
  bottom: 0;
  z-index: 2009;
  // animation: slide-bottom 1s;
  @include mediaXL {
    height: 92px;
    background-image: url('./assets/position-bottom-form.png');
  }
  @include mediaS {
    display: none;
  }
  .inputPosition {
    width: 360px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    margin-right: 12px;
    @include mediaXL {
      width: 460px;
      height: 64px;
      line-height: 64px;
      font-size: 20px;
      margin-right: 12px;
    }
  }

}
.pcBtnPositionHide {
  display: none;
}
.pcBtnPositionBottom {
  position: relative;
}
.pcBtnPositionBox {
  display: flex;
  position: absolute;
  top: 12px;
  left: 48%;
  @include mediaXL {
    top: 14px;
    left: 46%;
  }
}
.positionBtn {
  width: 200px;
  height: 65px;
  position: relative;
  bottom: 6px;
  background-image: url('./assets/btn.png');
  background-size: 100% 100%;
  text-align: center;
  line-height: 58px;
  color: #FFFFFF;
  font-size: 20px;
  cursor: pointer;
  @include mediaXL {
    width: 240px;
    line-height: 70px;
    height: 77px;
    bottom: 4px;
    font-size: 22px;
  }
}
.mobileBtnPositionZh {
  display: none;
  @include mediaS {
    cursor: pointer;
    display: block;
    margin-bottom: 50px;
    width: 375px;
    height: 100px;
    background-image: url('./assets/btn-mobel-zh.png');
    background-size: 100% 100%;
    z-index: 3003;
    margin: auto;
  }
}

.panel{
  top: 10px !important;
  bottom: auto;
}

.locale-en {
  .formRadio {
    display: flex;
    justify-content: center;

    font-weight: 400;
    span {
      text-align: center;
      @include mediaXL {
        width: 71%;
      }
    }
    .agreement {
      width: 83%;
    }
    @include mediaXL {
      height: 43px;
      padding: 0px 0 18px 0;
    }
    @include mediaS {
      padding: 8px 0 18px 0;
      justify-content: center;
      text-align: left;
      margin: auto;
      span {
        width: 70%;
        text-align: left;
      }
    }
    label {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50px;
      border: 1px solid rgb(204, 204, 204, 0.7);
      position: relative;
      cursor: pointer;
      margin-right: 9px;
      @include mediaXL {
        width: 18px;
        height: 18px;
      }
      @include mediaS {
        width: 14 px;
        height: 14px;
      }
    }
  }
  .formRadioPosition {
    span {
      width: 64%;
      text-align: center;
      @include mediaXL {
        width: 73%;
      }
    }
    label {
      width: 14px;
      height: 14px;
      position: relative;
      bottom: 0px;
      @include mediaXL {
        width: 18px;
        height: 18px;
      }
    }
  }
  .formTitle {
    font-size: 14px;
    display: flex;
    justify-content: center;
    line-height: 43px;
    font-weight: 800;
    .formTitle1 {
      // display: block;
      padding-right: 6px;
    }
    @include mediaXL {
      font-size: 20px;
      line-height: 58px;
    }
    @include mediaS {
      padding-bottom: 22px;
      display: block;
      .formTitle1 {
        // display: block;
        margin: auto;
        font-size: 18px;
        line-height: 36px;
        font-weight: 800;
        margin-left: 12px;
      }
      .formTitle2 {
        // display: block;
        margin: auto;
        font-size: 22px;
        font-weight: 800;
      }
    }
  }
  .mobileBtnPosition {
    display: none;
    @include mediaS {
      cursor: pointer;
      display: block;
      margin-bottom: 50px;
      width: 375px;
      height: 100px;
      background-image: url('./assets/btn-mobel.png');
      background-size: 100% 100%;
      z-index: 3003;
      margin: auto;
    }
  }
  .containerForm {
    height: 575px;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    animation: slide-bottom 1.5s;
    box-shadow: 0px -6px 8px rgba(255, 71, 41, 0.25), inset 0px 1px 0px #FFF8EE;
    border-radius: 22px 22px 0 0;
  }
  .containerPcForm {
    width: 380px;
    border: none;
    box-shadow: none;
    padding: 30px;
    padding-top: 56px;
    @include mediaXL {
      display: block;
      position: fixed;
      // bottom: 190px;
      // left: 16%;
      width: 520px;
      animation: slide-bottom 1.5s;
      border-radius: 22px;
      margin: auto;
      padding-top: 76px;
    }
  }
  .formTitlePc {
    width: 310px;
    height: 44px;
    top: -5px;
    left: 34px;
    line-height: 40px;
    font-weight: 800;
    @include mediaXL {
      width: 412px;
      height: 60px;
      left: 11%;
      top: -7px;
      line-height: 55px;
    }
  }
  .formError {
    font-size: 10px;
    color: #FF5353;
    padding: 0px 16px 0 16px;
    position: relative;
    bottom: 4px;
    @include mediaXL {
      padding: 0px 20px 0 20px;
    }
    @include mediaS {
      bottom: 8px;
      left: 28px;
      padding: 0px 20px 0 20px;
    }
  }
  .positionBoxWkImg {
    width: 112px;
    height: 94px;
    background-image: url('./assets/wk.png');
    background-size: 100% 100%;
    position: relative;
    bottom: 22px;
    left: 208px;
    @include mediaXL {
      width: 132px;
      height: 112px;
      bottom: 20px;
      left: 18%;
    }
    @include mediaS {
      display: none;
    }
  }
  .positionBoxSlogan {
    width: 271px;
    height: 52px;
    background-image: url('./assets/slogan.svg');
    background-size: 100% 100%;
    position: relative;
    bottom: 81px;
    left: 328px;
    @include mediaXL {
      width: 302px;
      height: 46px;
      bottom: 85px;
      left: 25.5%;
    }
    @include mediaS {
      display: none;
    }
  }
  .pcBtnPosition {
    position: fixed;
    width: 100%;
    height: 72px;
    background-image: url('./assets/position-bottom-form.png');
    background-size: 100% 100%;
    left: 0;
    bottom: 0;
    z-index: 2009;
    // animation: slide-bottom 1s;
    @include mediaXL {
      height: 92px;
      background-image: url('./assets/position-bottom-form.png');
    }
    @include mediaS {
      display: none;
    }
    .inputPosition {
      width: 360px;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      margin-right: 12px;
      @include mediaXL {
        width: 460px;
        height: 64px;
        line-height: 64px;
        font-size: 20px;
        margin-right: 12px;
      }
    }
  }
  .pcBtnPositionBox {
    display: flex;
    position: absolute;
    top: 12px;
    left: 48%;
    @include mediaXL {
      top: 14px;
      left: 48%;
    }
  }
  .positionBtn {
    width: 200px;
    height: 65px;
    position: relative;
    bottom: 6px;
    background-image: url('./assets/btn.png');
    background-size: 100% 100%;
    text-align: center;
    line-height: 65px;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
    @include mediaXL {
      width: 240px;
      line-height: 78px;
      height: 77px;
      bottom: 4px;
      font-size: 24px;
    }
  }
  .positionPhoneError{
    width: 258px;
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(400px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes btn-zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

