@import "~@/styles/viewport.scss";

.panel {
  top: 10px !important;
} 

.modal {
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: vm(14);
  height: 500px;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  font-family: PingFang SC;
  color: #484C70;

  .returnImg {
    position: absolute;
    left: vm(16);
    top: vm(20);
    cursor: pointer;
    z-index: 100;
  }

  .closeImg {
    position: absolute;
    right: vm(16);
    top: vm(20);
    cursor: pointer;
    z-index: 100;
  }

  .content {
    padding: vm(20);
    padding-top: vm(40);
    position: relative;
    height: calc(100% - vm(60));
  }

  :global {
    input:-webkit-autofill,
    textarea:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 vm(30) white inset !important;
    }
  }
}