.footer {
  color: #484c70;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .otherLoginTitle {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #c6c7d2;

    .line {
      width: 95px;
      background-color: #dfe0ed;
      height: 1px;
    }
  }

  .loginBtnContainer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .loginBtnContent {
    display: flex;
    justify-content: center;
  }

  .loginBtn {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 1px solid #dfe0ed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    margin-left: 21px;

    &:first-child {
      margin-left: 0;
    }

    &::before, .switchBtnImage {
      content: '';
      width: 28.8px;
      height: 28.8px;
      background-size: 28.8px 28.8px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .googleBtn {
    &::before {
      background-image: url('/images/login/google.svg');
    }
  }

  .appleBtn {
    &::before {
      background-image: url('/images/login/apple.svg');
    }
  }

  .googlContent {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    opacity: 0;
  }

  .facebookBtn {
    &::before {
      background-image: url('/images/login/facebook.svg');
    }
  }

  .wechatBtn {
    &::before {
      background-image: url('/images/login/wechat.svg');
    }
  }

  .privacy {
    margin-top: 20px;
    font-size: 14px;
    color: #8d8fa6;
    text-align: center;

    input {
      display: none;
    }

    label {
      width: 14px;
      height: 14px;
      display: inline-block;
      cursor: pointer;
      margin-right: 9px;
      margin-top: -1px;
      vertical-align: middle;
      box-sizing: border-box;
    }

    // label {
    //   display: inline-block;
    //   width: 14px;
    //   height: 14px;
    //   border-radius: 4px;
    //   border: 1.5px solid #8D8FA6;
    //   position: relative;
    //   cursor: pointer;
    //   margin-right: 9px;
    //   vertical-align: middle;
    //   box-sizing: border-box;
    // }

    // label::before {
    //   display: inline-block;
    //   content: ' ';
    //   border: 2px solid #fff;
    //   width: 4.5px;
    //   height: 2.5px;
    //   border-top: none;
    //   border-right: none;
    //   transform: rotate(-45deg);
    //   top: 4px;
    //   left: 4px;
    //   position: absolute;
    //   opacity: 0;
    // }

    // input:checked + label {
    //   background: #ff5353;
    //   border-color: #484C70;
    // }

    // input:checked + label::before {
    //   opacity: 1;
    //   transform: all 0.5s;
    // }

    .content {
        display: inline-block;
        vertical-align: middle;
        color: #8D8FA6;
        letter-spacing: 0.5px;
        user-select: none;
    }

    .highlight {
      color: #484C70;;
      cursor: pointer;
      text-decoration: underline;
      letter-spacing: 0.5px;
    }
  }
}
