@import "~@/styles/viewport.scss";

.registSuccess {
  .title {
    color: #1B1F4D;
    text-align: center;
    font-size: vm(22);
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .subTitle {
    color: #484C70;
    text-align: center;
    font-size: vm(16);
    font-style: normal;
    font-weight: 400;
  }

  .img {
    width: 90%;
    margin-top: vm(16);
    margin-bottom: vm(24);
    // padding: 0 vm(20);
    margin-left: auto;
    margin-right: auto;
  }
}

