@import "~@/styles/viewport.scss";

.button {
    display: flex;  
    height: vm(48);
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: vm(16);
    font-style: normal;
    font-weight: 600;
    border-radius: vm(24);
    background: rgba(255, 83, 83, 1);
    cursor: pointer;
    user-select: none;
    position: absolute;
    bottom: vm(48);
    width: calc(100% - vm(40));
}