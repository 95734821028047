@import "~@/styles/viewport.scss";
@mixin mediaS {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin mediaM {
  @media (min-width: 721px) and (max-width: 1259px) {
    @content;
  }
}

@mixin mediaL {
  @media (min-width: 1260px) {
    @content;
  }
}
@mixin mediaXL {
  @media (min-width: 1600px) {
    @content;
  }
}

.box {
  position: fixed !important;
  background: #ff5353;
  height: 80px;
  width: 100%;
  z-index: 2000;
  box-shadow: 0px 2px 10px rgba(130, 23, 22, 0.1);
  @include mediaS {
    height: vm(46);
  }
}

.container {
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  min-width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80px;
  text-align: center;
  @include mediaXL{
    padding: 0 100px;
  }
  @include mediaM {
    height: 80px;
    min-width: 100%;
  }
  @include mediaS {
    padding: 0 0 0 vm(49);
    height: vm(46);
    min-width: 100%;
    justify-content: flex-start;
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  @include mediaM {
    position: absolute;
    left: 100%;
    top: 76px;
    padding-top: 76px;
    width: 100vw;
    height: 100vh;
    background: #ff5353;
    overflow: scroll;
    z-index: 20;
    display: none;
    pointer-events: none;
    transition: opacity 0.3s;
  }
  @include mediaL{
    justify-content: flex-start;
  }
  @include mediaS {
    position: absolute;
    left: 100%;
    top: vm(46);
    padding-top: vm(46);
    width: 100vw;
    height: 100vh;
    // background: #ff5353 url('/icon/mobile-nav-background.png') left bottom / contain no-repeat;
    background: #ff5353;
    overflow: scroll;
    z-index: 1000;
    display: none;
    pointer-events: none;
  }
  &.contentMobileShow {
    left: 0;
    @include mediaM {
      display: block;
      pointer-events: auto;
    }
    @include mediaS {
      display: block;
      pointer-events: auto;
    }
  }
}

.navBg {
  display: none;
  width: 100%;
  @include mediaM{
    display: block;
  }
  @include mediaS {
    display: block;
    position: fixed;
    bottom: 0;
    font-size: 0;
    pointer-events: none;
  }
}

.logo {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 120px;
  height: 40px;
  cursor: pointer;
  position: relative;
  img {
    width: 100%;
  }
  @include mediaS {
    width: vm(69);
    height: vm(23);
    img {
      width: vm(69);
      height: vm(23);
    }
  }
}

.logo-link {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  position: absolute;
}

.nav {
  z-index: 1000;
  position: relative;
  display: flex;
  @include mediaM {
    flex-direction: column;
    width: 100%;
    margin-bottom: 60px;
  }
  @include mediaL {
    margin-left: 64px;
  }
  @include mediaXL {
    margin-left: 70px;
  }
  @include mediaS {
    height: 100%;
    flex-direction: column;
    width: 100%;
    margin-bottom: vm(460);
  }
}

.nav-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
  word-break: keep-all;
  @include mediaL {
    font-size: 14px;
    padding: 0 16px;
  }
  @include mediaXL {
    font-size: 16px;
    padding: 0 30px;
  }
  @include mediaM {
    height: 57px;
    margin-right: 22px;
    margin-left: 22px;
    border-bottom: 1px solid #ffffff;
    font-size: 22px;
    justify-content: flex-start;
    padding: 0 20px 0 3px;
    overflow: hidden;
    box-sizing: border-box;
    width: calc(100vw - 22px - 22px);
  }
  @include mediaS {
    height: vm(57);
    margin-right: vm(22);
    margin-left: vm(22);
    border-bottom: vm(1) solid #ffffff;
    font-size: vm(22);
    justify-content: flex-start;
    padding: 0 vm(20) 0 vm(3);
    overflow: hidden;
    box-sizing: border-box;
    width: calc(100vw - #{vm(22)} - #{vm(22)});
  }
  a {
    color: #ffffff;
    text-decoration: none;
    @include mediaL {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include mediaM {
      // height: 15.2vmin;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 57px;
    }
    @include mediaS {
      height: 15.2vmin;
      display: flex;
      align-items: center;
      justify-content: center;
      height: vm(57);
    }
  }
}

.navTextNoHref {
  @extend .nav-text;
  cursor: default;
}

.nav-item {
  height: 100%;
  font-size: 16px;
  color: #ffffff;
  transition: background-color 0.3s;
  letter-spacing: 0.06em;

  @include mediaL {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #ed0000;
    }
  }
  @include mediaM {
    height: auto;
    margin-right: 0;
  }
  @include mediaS {
    height: auto;
    margin-right: 0;
  }
}

.menuContainer {
  @include mediaM {
    width: 18px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: transparent;
  }
  @include mediaS {
    width: vm(18);
    cursor: pointer;
    position: absolute;
    top: 47%;
    left: vm(22);
    transform: translateY(-50%);
    -webkit-tap-highlight-color: transparent;
  }
  @include mediaL {
    display: none;
  }
}
.language-box {
  color: #ffffff;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  &:hover {
    @include mediaM {
      .language-text-box {
        &::after {
          transition: all 0.3s;
          transform: rotate(180deg);
        }
      }
      .language-select-box {
        left: 0;
        opacity: 1;
        transition: 0s left, opacity 0.3s;
      }
    }
    @include mediaL {
      .language-text-box {
        &::after {
          transition: all 0.3s;
          transform: rotate(180deg);
        }
      }
      .language-select-box {
        left: 0;
        opacity: 1;
        transition: 0s left, opacity 0.3s;
      }
    }
  }
}
.language-text-box {
  cursor: pointer;
  border: 1px solid #ffffff;
  box-sizing: border-box;

  @include mediaM {
    width: 92px;
    height: 29px;
    left: 1435px;
    top: 30px;
    border-radius: 100px;
    background: url("/icon/languageBg.svg") 12px 50% / 18px no-repeat;
    border-radius: 100px;
    &::after {
      width: 0px;
      height: 0px;
      position: absolute;
      top: 36px;
      right: 8px;
      content: "";
      border-top: 4px solid #fff;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      transition: all 0.3s;
    }
  }
  @include mediaL {
    width: 92px;
    height: 29px;
    left: 1435px;
    top: 30px;
    border-radius: 100px;
    background: url("/icon/languageBg.svg") 12px 50% / 18px no-repeat;
    border-radius: 100px;
    &::after {
      width: 0px;
      height: 0px;
      position: absolute;
      top: 36px;
      right: 8px;
      content: "";
      border-top: 4px solid #fff;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      transition: all 0.3s;
    }
  }
  @include mediaS {
    width: vm(90);
    height: vm(24);
    border-radius: vm(16);
    background: url("/icon/languageBg.svg") #{vm(8)} 50% / #{vm(12)} no-repeat;
    &::after {
      width: 0px;
      height: 0px;
      position: absolute;
      top: vm(21);
      right: vm(5);
      content: "";
      border-top: 4px solid #fff;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      transition: all 0.3s;
    }
  }
}

.language-text {
  font-family: sans-serif;
  @include mediaL {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    margin-left: 10px;
    color: #ffffff;
  }
  @include mediaM {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    margin-left: 10px;
    color: #ffffff;
  }
  @include mediaS {
    display: block;
    font-size: vm(14);
    line-height: vm(14);
    margin-left: vm(6);
    position: relative;
    top: 45%;
    transform: scale(0.83) translateY(-50%);
  }
}

.language-item-text {
  color: #333333;
  text-decoration: none;
  display: block;
  @include mediaL {
    font-size: 16px;
    line-height: 22px;
    line-height: 22px;
    padding: 10px 0 0 10px;
  }
  @include mediaM {
    font-size: 16px;
    line-height: 22px;
    line-height: 22px;
    padding: 10px 0 0 10px;
  }
  @include mediaS {
    font-size: vm(12);
    line-height: vm(14);
    padding: vm(10) 0 0 vm(10);
  }
}

.language-item-active {
  color: #ff5353;
}

.language-select-box {
  background: #ffffff;
  position: absolute;
  text-align: left;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  opacity: 0;
  @include mediaL {
    width: 92px;
    height: 74px;
    border-radius: 10px;
    top: 70px;
  }
  @include mediaM {
    width: 92px;
    height: 74px;
    border-radius: 10px;
    top: 70px;
  }
  @include mediaS {
    width: vm(80);
    height: vm(58);
    border-radius: vm(10);
    top: vm(40);
    left: -100vw;
  }
}

.languageBoxActive {
  -webkit-tap-highlight-color: transparent;
  @include mediaS {
    .language-text-box {
      &::after {
        transition: all 0.3s;
        transform: rotate(180deg);
      }
    }
    .language-select-box {
      left: 0;
      opacity: 1;
      transition: 0s left, opacity 0.3s;
    }
  }
}

.line {
  width: 20px;
  display: inline-block;
  position: relative;
  &:before {
    content: "";
    width: 20px;
    height: 0px;
    border-top: 1px solid rgb(255 255 255);
    position: absolute;
    top: 50%;
    right: 0;
  }
}

.student-center {
  min-width: 124px;
}

.student-center, .login {
  -webkit-tap-highlight-color: transparent;
  @include mediaS {
    margin-left: vm(10);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include mediaM {
    margin-left: 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include mediaL {
    margin-left: 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.avatarContainer {
  margin-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 56px;
  -webkit-tap-highlight-color: transparent;

  @include mediaS {
    // width: vm(80);
    // height: vm(58);
    // border-radius: vm(10);
    // top: vm(40);
    // left: -100vw;
    width: auto;
    &::after {
      display: none;
    }
  }

  .selectBox {
    background: #ffffff;
    position: absolute;
    text-align: left;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    opacity: 0;
    width: 160px;
    border-radius: 10px;
    top: 70px;
    padding: 8px;
    box-sizing: border-box;
    color: #484C70

    // @include mediaS {
    //   width: vm(80);
    //   height: vm(58);
    //   border-radius: vm(10);
    //   top: vm(40);
    //   left: -100vw;
    // }
  }

  .userInfo {
    padding: 0 8px;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #ebecfa;
    height: 24px;
    box-sizing: border-box;

    .icon {
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .text {
      font-size: 14px;
      margin-left: 8px;
      max-width: calc(100% - 24px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

   .userLine {
    height: 1px;
    background: #ebecfa;
    margin-top: 8px;
   }

  .selectItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
    margin-top: 8px;
    padding: 0 8px;
    border-radius: 4px;

    .icon {
      width: 16px;
      height: 16px;
      background-image: url(/images/login/logout_black.svg);
    }

    .text {
      font-size: 14px;
      margin-left: 8px;
    }

    &:hover {
      color: #FF5353;
      background-color: #FFF5F5;

      .icon {
        background-image: url(/images/login/logout.svg);
      }
    }
  }

  &::after {
    content: '';
    background-image: url('/images/login/avatar_down.svg');
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
      &::after {
        transition: all 0.3s;
        transform: rotate(180deg) translateY(50%);
      }

      .selectBox {
        right: 0;
        opacity: 1;
        transition: 0s left, opacity 0.3s;
      }
  }
}

.avatar {
  height: 36px;
  width: 36px;

  @include mediaS {
    height: vm(28);
    width: vm(28);
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.student-center-btn, .login-btn {
  border: 1px solid #FFF;
  box-shadow: 0px 1px 3px rgba(223, 31, 31, 0.25);
  border-radius: 100px;
  color: #ff5353;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.06em;
  padding: 4px 16px;
  color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  box-sizing: border-box;
  height: 29px;

  @include mediaS {
    text-indent: initial;
    width: auto;
    height: vm(24);
    padding: 0 vm(10);
    line-height: vm(25);
    // box-shadow: 0px vm(1) vm(3) rgba(223, 31, 31, 0.25);
    border-radius: vm(16);
    text-decoration: none;
    font-weight: 600;
    font-size: vm(12);
    letter-spacing: 0.06em;
    span {
      transform: scale(0.83);
      display: block;
    }
  }
}

.student-center-btn {
  padding: 4px 16px;
}

.loginBtnDiff {
  // background: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // color: #ff5353;
  // width: 127px;
  // box-sizing: border-box;
  // height: 29px;
  // font-size: 16px;
  // @include mediaS {
  //   padding: 0 vm(5);
  //   width: vm(72);
  //   height: vm(24);
  //   font-size: vm(14);
  // }
}

.right-nav {
  position: relative;
  height: 100%;
  display: flex;
  @include mediaS {
    position: absolute;
    right: vm(21);
  }
  @include mediaM {
    position: absolute;
    right: 20px;
  }
}

.classBtn{
  border: 1px solid #FFF;
  background: #fff;
  color: #ff5353;
  // width: 127px;
  box-sizing: border-box;
  height: 29px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.06em;
  border-radius: 100px;
  padding: 4px 16px;
  @include mediaS {
    width: vm(300);
    height: vm(48);
    font-size: vm(16);
    border-radius: vm(100);
    position: fixed;
    bottom: vm(24);
    background: #ff5353;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3000;
    padding: vm(10) 0;
  }

}

// 移动端 导航打开的表单 和 首页表单问题
// 默认情况 首页的移动端吸底按钮 层级更改 这样换起弹窗 nav遮挡住
// 点击展开nav 层级比 移动端吸底更高 遮挡住按钮
.highZIndex{
  z-index: 2020;
}
// 英文样式
.locale-en {
  .nav-text {

    @include mediaL {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 17px;
      color: #ffffff;
      text-decoration: none;
      white-space: nowrap;
      word-break: keep-all;
    }
    @include mediaS{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 vm(17);
      color: #ffffff;
      text-decoration: none;
      white-space: nowrap;
      word-break: keep-all;
    }
  }
  .nav-item {
    margin-right: 0;

    @include mediaL {
      letter-spacing: 0.03em;
    }
    @include mediaS {
      letter-spacing: initial;
    }
  }

  .container {
    @include mediaL {
      padding: 0 30px;
    }
    @include mediaXL {
      padding: 0 60px;
    }
  }

  .student-center {
    min-width: 158px;
  }

  .student-center-btn {
    font-family: HelveticaNeueLTPro, sans-serif;
    padding-top: 5px;
    padding-bottom: 3px;

    // @include mediaL {
    //   // height: 29px;
    //   // line-height: 30px;
    //   padding: 7px 20px 3px 10px;
    //   min-width: 118px;
    //   font-weight: normal;
    //   letter-spacing: 0.03em;

    // }

    // @include mediaM {
    //   padding: 7px 20px 3px 10px;
    //   min-width: 118px;
    //   font-weight: normal;
    //   letter-spacing: 0.03em;
    // }
  }
  .language-text {
    font-family: HelveticaNeueLTPro, sans-serif;
    @include mediaL {
      line-height: 31px;
      position: relative;
      top: 2px;
    }
    @include mediaM {
      line-height: 31px;
    }
    @include mediaS {
      font-size: vm(14);
      line-height: vm(14);
      top: 52%;
    }
  }
  .line {
    @include mediaM {
      top: -3px;
    }
    @include mediaS {
      top: -3px;
    }
    @include mediaL {
      top: -2px;
    }
  }
  .nav-text {
    @include mediaL {
      padding: 0 10px;
    }
    @include mediaXL {
      padding: 0 20px;
    }
    @include mediaM {
      font-size: 18px;
    }
    @include mediaS {
      font-size: vm(18);
    }
  }
  .login-btn{
    @include mediaL {
      padding: 6px 16px 2px 16px;
    }
  }
  .classBtn{
    @include mediaL {
      padding: 6px 16px 2px 16px;
    }
    @include mediaS {
      padding: vm(14) 0 0 0;
    }
  }
}
