@import "~@/styles/mediaQuery.scss";
.input-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  box-sizing: border-box;
  background: #F7F7F7;
  border-radius: 50px;
  margin-bottom: 8px;
  height: 44px;
  @include mediaXL {
    margin-bottom: 12px;
    height: 56px;
  }
  @include mediaS {
    height: 52px;
    width: calc(100% - 44px);
    margin-left: 22px;
    margin-bottom: 10px;
  }
}

.input {
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
  text-align: left;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 14px;
  padding: 0 16px;
  height: 100%;
  &::placeholder {
    color: #c2c2c2;
    text-align: left;
    font-weight: 400;
  }
  @include mediaXL {
    font-size: 18px;
    padding: 0 20px;
  }
  @include mediaS {
    font-size: 15px;
    padding: 0 22px;
  }
}
.inputPositionT {
  font-size: 16px;
  @include mediaXL {
    font-size: 20px;
  }
}

:global {
  .wk-form-item-error {
    :local {
      .input {
        border: 1px solid #FF5353;
        // background: transparent;
      }
    }
  }
}
