.loginPC {

  .title {
    color: #1B1F4D;
    text-align: center;
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .subTitle {
    color:#484C70;
    text-align: center;
    font-family: PingFang SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-top: 16px;
  }

  .description {
    color:  #484C70;
    text-align: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    margin-top: 8px;
  }

  .tab {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    color: #484C70;
    font-size: 18px;

    .tabPane {
      width: 50%;
      text-align: center;
      cursor: pointer;
      height: 40px;
      position: relative;
    }

    .line {
      border-radius: 999px;
      background: #484C70;
      width: 40px;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
