@import "~@/styles/viewport.scss";

.footer {
    color: #484C70;
    text-align: center;
    font-size: vm(16);
    font-style: normal;
    font-weight: 400;

    .otherLoginTitle {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: vm(16);
        color: #C6C7D2;

        .line {
            width: vm(82);
            background-color: #DFE0ED;
            height: 1px;
        }
    }

    .loginBtnContainer {
        margin-top: vm(8);
        display: flex;
        justify-content: center;
    }

    .loginBtnContent {
        display: flex;
        justify-content: center;
        width: vm(110);
    }

    .loginBtn {
        border-radius: 50%;
        border: 1px solid #DFE0ED;
        height: vm(44);
        width: vm(44);
        font-weight: 500;
        position: relative;
        cursor: pointer;
        margin-left: vm(22);

        &:first-child {
            margin-left: 0;
        }

        &::before {
            content: '';
            width: vm(30);
            height: vm(30);
            background-size: vm(30) vm(30);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .googleBtn {
        &::before {
            background-image: url('/images/login/google.svg');
        }
    }

    .appleBtn {
        &::before {
            background-image: url('/images/login/apple.svg');
        }
    }

    .googlContent {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        opacity: 0;
    }

    .facebookBtn {
        &::before {
            background-image: url('/images/login/facebook.svg');
        }
    }

    .wechatBtn {
        &::before {
            background-image: url('/images/login/wechat.svg');
        }
    }

    .privacy {
        margin-top: vm(16);
        font-size: vm(14);
        color: #8D8FA6;

        .highlight {
            color: #FF5353;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}