@import "~@/styles/viewport.scss";

.resetFormContainer {
  .title {
    color: #1B1F4D;
    text-align: center;
    font-family: PingFang SC;
    font-size: vm(22);
    font-style: normal;
    font-weight: 600;
    margin-bottom: vm(16);
  }

  .subTitle {
    color: #484C70;
    text-align: center;
    font-size: vm(14);
    font-style: normal;
    font-weight: 400;
    margin-bottom: vm(16);
  }
}

.form {

  .redBorder {
    border-color: #FF5353;
  }

  .registInfoContainer {
    margin-top: vm(-17);
    margin-bottom: 12px;
  }

  .registInfo {
    color: #484C70;
    font-size: vm(14);
    font-style: normal;
    font-weight: 400;
    padding-top: vm(10);

    span {
      color: #FF5353;
      cursor: pointer;
    }
  }

  .password {
    position: absolute;
    right: vm(16);
    top: 50%;
    transform: translate(0, -50%);
    height: vm(20);

    img {
      width: vm(20);
      height: vm(20);
      cursor: pointer;
    }
  }
  
  .submit {
    display: flex;  
    height: vm(48);
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: vm(16);
    font-style: normal;
    font-weight: 600;
    border-radius: vm(24);
    background: #FFCFCF;
    background: rgba(255, 83, 83, 1);
    cursor: pointer;
    user-select: none;
  }
}

