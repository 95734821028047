.resetFormContainer {
  .title {
    color: #1B1F4D;
    text-align: center;
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .subTitle {
    color: #484C70;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: vm(16);
  }
}

.form {
  margin-top: 24px;

  .later {
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #484C70;
  }

  .redBorder {
    border-color: #FF5353;
  }

  .registInfoContainer {
    margin-top: -17px;
  }

  .registInfo {
    color: #484C70;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;

    span {
      color: #FF5353;
      cursor: pointer;
    }
  }

  .password {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    height: 20px;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  
  .submit {
    display: flex;  
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 24px;
    background: #FFCFCF;
    background: rgba(255, 83, 83, 1);
    cursor: pointer;
    user-select: none;
  }
}

