@import "~@/styles/viewport.scss";

.fade {
  opacity: 0;
}

.fade-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-out {
  opacity: 1;
}

.fade-out-active {
  opacity: 0;
  transition: opacity 0.3s;
}

.panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 572px;
  background: #fff;
  z-index: 1000;
  padding: vm(10) 0 vm(24) 0;
  width: 100vw;
  margin-left: calc(50%);
  transform: translate(-50%, 0);
  box-sizing: border-box;
  z-index: 9999999999;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -webkit-tap-highlight-color: transparent;
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 vm(16);
  padding-top: vm(10);

  .returnImg {
    cursor: pointer;
    z-index: 100;
  }

  .closeImg {
    cursor: pointer;
    z-index: 100;
  }
}

.tip-box {
  display: flex;
  align-items: center;
  margin-top: vm(10);
  background-color: #FFF5F5;
  padding: vm(10) vm(20);

  svg {
    width: vm(16);
    height: vm(16);
  }
  span {
    color: #ff5353;
    font-size: vm(13);
    margin-left: vm(6);
    font-weight: 400;
    max-width: calc(100% - vm(22));
  }
}

.slide-in {
  transform: translate(-50%, 100%);
}

.slide-in-active {
  transform: translate(-50%, 0%);
  transition: transform 0.3s;
}

.slide-out-active {
  transform: translate(-50%, 100%);
  transition: transform 0.3s;
}

.touch-bar {
  position: absolute;
  right: 0;
  text-align: center;
  width: vm(24);
  top: 30%;
  // transform: translateY(-55%);
  font-weight: bold;
  color: #ccc;
  padding: vm(12) 0;
  border-radius: vm(30);
  transition: all 0.3s;
  user-select: none;
  touch-action: none;
  transform-origin: 100% 50%;

  .letter-code {
    // padding: vm(1) 0;
    font-size: vm(12);
    font-weight: 400;
    // &-active {
    //   color: #ff5353;
    // }
  }

  // &.touching {
  //   background: #f7f7f7;
  //   transform: translateY(-55%) scale(1.02);
  // }
}

.letter {
  position: sticky;
  color: rgba(0, 0, 0, 0.4);
  top: vm(-2);
  background: #f9f9f9;
  padding: 0 0 0 vm(15);
  height: vm(44);
  width: 100%;
  left: vm(-20);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

// .touchingStatic {
//   .letter {
//     position: static;
//   }
// }

.country-codes {
  height: 85%;
  overflow: auto;
  margin-top: vm(10);
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
}

.code {
  display: inline-block;
  margin-left: auto;
  color: #8e8e8e;
  padding-right: vm(4);
}

.codeItem {
  display: flex;
  margin: 0 vm(24) 0 vm(14);
  padding: vm(14) 0 vm(14) 0;
  border-bottom: 1px solid #f3f3f3;
  box-sizing: border-box;
  // &:active {
  //   background: rgb(241, 240, 240);
  // }
  &.selected {
    // color: #ff5353;

    .code {
      color: inherit;
    }
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

.code-group {
  margin-bottom: vm(18);
  &:last-child {
    padding-bottom: vm(40);
  }
}

.close {
  position: absolute;
  right: vm(12);
  top: vm(12);
  width: vm(20);
  height: vm(20);
  z-index: 1;
}

.searchIcon {
  width: vm(20);
  height: vm(20);
  position: relative;
  left: vm(14);
}

.searchInput {
  background-color: #F9F9F9;
  border: none;
  height: vm(40);
}

.searchInputContainer {
  padding: 0 vm(20);
  margin: vm(10) 0;
}
