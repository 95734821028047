@import "~@/styles/viewport.scss";

.logout {
  padding: 20px 0;
  color:  #484C70;
  font-size: 14px;
  position: relative;

  .avatarContainer {
    position: absolute;
    top: -50px;
    left: -4px;
    width: calc(100vw - vm(70));
    display: flex;
    align-items: flex-end;

    .avatarContent {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      margin-right: vm(5);
      background-color: #F5F5FC;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatar {
      height: 48px;
      width:  48px;
    
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    span {
      font-size: vm(18);
      max-width: calc(100% - vm(78));
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .item {
    height: vm(64);
    display: flex;
    align-items: center;
    padding: 0 vm(16);
  }

  .stuCenter {
    border-radius: 12px;
    background-color: #fff;
  }

  .itemIcon {
    width: vm(16);
    height: vm(16);
    margin-right: vm(12);
  }
}