@import '~@/styles/viewport.scss';
@import '~@/styles/color.scss';
@import '~@/styles/mediaQuery.scss';
@import '~@/styles/font-HelveticaNeueLTPro.scss';

@mixin theme($color, $background) {
  color: $color;
  background: $background;
  &.reverse {
    color: $background;
    background: $color;
  }
}

.container {
  border-radius: 50px;
  padding: 5px 20px;
  border: none;
  line-height: 1.4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: none;
  @include mediaS {
    padding: vm(5) vm(14);
  }
}

.size-m {
  padding: 6px 17px;
  font-size: 18px;
  @include mediaS {
    padding: vm(3) vm(12);
    font-size: vm(14);
  }
}

.size-l {
  font-size: 22px;
  padding: 8px 26px;
  @include mediaS {
    padding: vm(5) vm(14);
    font-size: vm(18);
  }
}

.theme-red {
  @include theme(#ffffff, $red);
}

.theme-blue {
  @include theme(#ffffff, $blue);
}

.theme-pewter {
  @include theme(#ffffff, #61aff8);
}

.theme-white {
  @include theme(#999999, #ffffff);
}

.theme-gray {
  @include theme(#999, #f0f0f0);
}

.theme-yellow {
  @include theme(#fff, #ffba69);
}

.theme-yellow2 {
  @include theme(#fff, #ffba69);
}

.theme-yellow3 {
  @include theme(#fff, #FFC440);
}


.theme-green {
  @include theme(#fff, #16d1c6);
}

.theme-purple {
  @include theme(#fff, #4e7af5);
}

.theme-royalblue {
  @include theme(#fff, #6153ff);
}
.theme-royalblue2 {
  @include theme(#fff, #C3A5FF);
}
.theme-royalblue3 {
  @include theme(#fff, #9D7CFE);
}

.theme-royalblue4 {
  @include theme(#fff, #7860FE);
}
.theme-royalblue5 {
  @include theme(#fff, #4842EA);
}
.theme-royalblue6 {
  @include theme(#fff, #9269FF);
}
.theme-mediumorchid {
  @include theme(#fff, #9f53ff);
}

.theme-pinkPurple {
  @include theme(#fff, #C04AEA);
}

.theme-orange2 {
  @include theme(#fff, #FD7B4C);
}

.theme-orange {
  @include theme(#fff, #FFBA69);
}

.theme-blue2 {
  @include theme(#fff, #16D1C6);
}

.theme-blue3 {
  @include theme(#fff, #2D9AFF);
}
.theme-green2 {
  @include theme(#fff, #74D117);
}

// 英文样式
.locale-en {
  font-family: HelveticaNeueLTPro, sans-serif;
  font-weight: normal;
}
