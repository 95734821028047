@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";

.container {
  @include mediaL {
    margin-bottom: 52px;
  }
  @include mediaS {
    margin-top: vm(26);
    margin-bottom: vm(42);
  }
}

.input {
  margin: 0;
  @include mediaL {
    height: 52px;
  }
  @include mediaS {
    height: vm(44);
    width: 100% !important;
    margin-left: 0 !important;
  }

  :global {
    input {
      border: 1px solid #f1f1f2;
      background: #f1f1f2;
      color: #333333;
      height: 100%;
      @include mediaL {
        font-size: 18px;
        padding: 0 20px;
        border-radius: 48px;
      }
      @include mediaS {
        font-size: vm(14);
        padding: 0 vm(16);
      }
      &::placeholder {
        color: #999;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition-delay: 111111s;
      -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
    }
  }
}

.buttonInput {
  position: absolute;

  @include mediaL {
    right: 4px;
    top: 4px;
  }
  @include mediaS {
    right: vm(4);
    top: vm(4);
  }
}

.button {
  box-shadow: none !important;
  @include mediaL {
    height: 44px;
    width: 96px;
  }
  @include mediaS {
    height: vm(36);
    width: vm(76);
  }
}

.formItem {
  position: relative;

  @include mediaL {
    width: 400px;
  }

  @include mediaS {
    margin: auto;
    width: vm(315);
    margin-bottom: 0 !important;
  }
}

.formItemErr {
  position: absolute;
}

.formItemLabel {
  @include mediaL {
    &::before {
      top: 5px !important;
      transform: inherit !important;
    }
  }
  @include mediaS {
    padding: 0 !important;
    text-align: center;
    font-weight: 700;
    font-size: vm(14) !important;
    line-height: vm(18) !important;
    color: #333333 !important;
    margin-bottom: vm(16) !important;
    &::before {
      display: none;
    }
  }
}

.localeEn {
  .button {
    box-shadow: none !important;
    @include mediaL {
      // height: 44px;
      width: 120px;
    }
    @include mediaS {
      // height: vm(36);
      width: vm(90);
    }
  }
  .formItem {
    @include mediaL {
      width: 520px;
    }
    @include mediaS {
    }
  }
  .formItemLabel {
    @include mediaS {
      font-weight: 700;
    }
  }
  .input {
    @include mediaL {
      // width: 460px;
    }
  }
}

.successIcon {
  @include mediaL {
    position: absolute;
    width: 55px;
    height: 55px;
    top: -4px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  @include mediaS {
    position: absolute;
    width: vm(48);
    height: vm(48);
    top: vm(-6);
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
