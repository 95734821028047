@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';

.menuIcon {
  display: inline-flex;
  flex-direction: column;
  width: 18px;
  height: 12px;
  justify-content: space-between;
  vertical-align: middle;
  transition: transform 0.3s;
  i {
    display: block;
    width: 100%;
    height: 1px;
    background: #fff;
    transition: transform 0.3s, opacity 0.3s;
  }

  &.open {
    transform: translateX(6px);
    i {
      &:nth-child(1) {
        transform: rotate(45deg) translateY(7.8px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translateY(-7.8px);
      }
    }
  }
}
