@import "~@/styles/viewport.scss";

.loginPC {

  .title {
    color: #1B1F4D;
    text-align: center;
    font-family: PingFang SC;
    font-size: vm(22);
    font-style: normal;
    font-weight: 600;
  }

  .tab {
    display: flex;
    justify-content: space-between;
    margin-top: vm(8);
    color: #484C70;
    font-size: vm(16);

    .tabPane {
      width: 50%;
      text-align: center;
      cursor: pointer;
      height: 35px;
      position: relative;
      -webkit-tap-highlight-color: transparent;
    }

    .line {
      border-radius: 999px;
      background: #484C70;
      width: vm(40);
      height: vm(4);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
