.code {
  position: absolute;
  right: 16px;
  top: 50%;
  padding-left: 20px;
  transform: translate(0, -50%);
  color: #ff5353;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
}
