@import '~@/styles/scrollbar.scss';

.code-box {
  width: 70px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  cursor: pointer;
  transform: rotate();
  user-select: none;
  
  span {
    font-size: 16px;
    line-height: 16px;
  }
}

.icon {
  transition: all .2s;
}

.icon-rotate {
  transform: rotate(180deg);
  transition: all .2s;
}

.list {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border: 0.5px solid #DFE0ED;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  z-index: 9;
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.search {
  height: 48px;
  background: #FFFFFF;
  border: none;
  border-bottom: 1px solid #DFE0ED;
  border-radius: 12px 12px 0px 0px;
  input {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.9);
    padding-left: 12px;
    &::placeholder {
      font-size: 14px;
      color: #D1D1D1;
    }
  }
}

.search-icon {
  margin-left: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 18px;
    height: 18px;
  }
}

.letter-box {
  margin: 0 14px;
  display: flex;
  flex-wrap: wrap;
}

.letter {
  width: 24px;
  height: 24px;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #FF5353;
  }
  &-active {
    color: #FF5353;
    background: #FFF4F4;
    border-radius: 4px;
  }
}

.list-tip {
  margin-top: 12px;
  background-color: #fff4f4;
  display: flex;
  align-items: center;
  padding: 10px;
}

.tip-icon {
  width: 16px;
  height: 16px;
}

.tip-text {
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #FF5353;
  max-width: calc(100% - 24px);
}

.list-box {
  max-height: 160px;
  padding: 0 12px 16px 12px;
  overflow-x: hidden;
  @include scrollBar;
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  position: relative;
  &:hover {
    color: #FF5353;
  }
  span {
    &:first-child {
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &-active {
    color: #FF5353
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
