@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';



.voiceDrawer {
  // top: 200px;
  font-size: vm(15);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 999 !important;

  .returnImg {
    position: absolute;
    left: vm(10);
    top: vm(10);
    cursor: pointer;
  }

  .closeImg {
    position: absolute;
    right: vm(10);
    top: vm(10);
    cursor: pointer;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: vm(16);
    line-height: 24px;
    text-align: center;
    color: #1b1f4d;
    margin-bottom: 24px;
    padding-top: 40px;
  }

  .subTitle {
    color: #484c70;
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-size: vm(14);
    line-height: 22px;
    margin-bottom: 16px;
    padding: 0 44px;
  }

  .countryCode {
    height: 48px;
    font-size: vm(15);
    width: vm(75);
    background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3)) !important;
    > span {
      padding-left: vm(20);
      display: inline-block;
    }
    > svg {
      // margin-left: vm(50);
      display: inline-block;
    }
  }

  .formItemErr {
    padding: 0 vm(20);
    font-size: vm(15);
  }

  .input {
    height: 48px;
    border: 1px solid #dfe0ed;
    border-radius: 24px;
    background-color: #fff;
    font-size: vm(15);
    padding: 0 vm(20);
    width: 100% !important;
    margin-left: 0 !important;
  }

  .phoneInput {
    @extend .input;
    padding: 0 vm(15) 0 vm(80);
    background-color: rgba(247, 247, 247, 1);
  }

  .form {
    padding: 0 vm(24);
  }

  .verifyCode {
    box-shadow: none;
    background: #fff;
    color: #ff6b00;
    cursor: pointer;
    font-weight: 400;

    border-radius: 0 vwPC(50) vwPC(50) 0;
    padding-right: vwPC(5);
    white-space: nowrap;
    font-size: vm(15) !important;
    height: 46px;
    // &:disabled {
    //   background: #f7f7f7;
    // }
  }

  .verifyCodeContainer {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 20px;
  }

  .code {
    position: relative;
  }

  .fixFormItem {
    margin-bottom: 32px;
  }

  .submitBtn {
    width: 100%;
    height: 48px;
    font-size: vm(16);
    margin-bottom: vm(20);
    box-shadow:none;
  }

  :global {
    .rc-dialog-close {
      font-weight: 400;
      font-size: 24px;
    }
  }
}
