@import '~@/styles/viewport.scss';
@mixin mediaS {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin mediaM {
  @media (min-width: 721px) and (max-width: 1259px)  {
    @content;
  }
}

@mixin mediaL {
  @media (min-width: 1260px) {
    @content;
  }
}
@mixin mediaXL {
  @media (min-width: 1600px) {
    @content;
  }
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mediaL {
    &:hover {
      .options {
        left: 0;
        opacity: 1;
        transition: 0s left, opacity 0.3s;
      }
    }
  }

  @include mediaS {
    flex-direction: column;
    align-items: flex-start;
  }
  @include mediaM {
    flex-direction: column;
    align-items: flex-start;
  }
}

.options {
  @include mediaS {
    display: none;
    // vm(-1) 将上方的边框遮挡住
    margin-top: vm(-1);
    box-sizing: border-box;
    background: #ffffff;
    width: 100%;
    text-align: left;
    padding: 0 vm(22);
  }
  @include mediaM {
    display: none;
    // vm(-1) 将上方的边框遮挡住
    margin-top: -1px;
    box-sizing: border-box;
    background: #ffffff;
    width: 100%;
    text-align: left;
    padding: 0 22px;
  }
  @include mediaL {
    left: -100vw;
    padding: 15px 22px 20px 13px;
    position: absolute;
    bottom: 0;
    opacity: 0;
    //left: 0;
    transform: translateY(100%);
    border-top: 5px solid #f72e2e;
    background: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    white-space: nowrap;
    text-align: left;
    transition: 0s left 300ms, opacity 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 0 0 10px 10px;
    backdrop-filter: blur(10px);
    min-width: 100%;
    box-sizing: border-box;
  }
}

.options-active {
  display: block;
}

.option-item {
  &:last-of-type {
    a {
      border-bottom: none;
    }
  }

  a {
    padding: 9px 0;
    color: #333;
    display: block;
    text-decoration: none;

    &:hover {
      @include mediaL {
        color: #ff5353;
      }
    }

    @include mediaS {
      padding: vm(13) vm(3);
      font-size: vm(18);
      line-height: vm(27);
      border-bottom: vm(1) solid #000;
    }
    @include mediaM {
      padding: 13px 3px;
      font-size: 18px;
      line-height: 27px;
      border-bottom: 1px solid #000;
    }
  }
}

.arrow {
  font-size: 0;
  @include mediaS {
    padding: vm(8);
    position: absolute;
    top: vm(17);
    right: vm(32);
    transform: rotate(180deg);
    transition: transform 0.3s;
    img {
      display: block;
    }
  }
  @include mediaM{
    padding: 8px;
    position: absolute;
    top: 17px;
    right: 32px;
    transform: rotate(180deg);
    transition: transform 0.3s;
    img {
      display: block;
    }
  }
  @include mediaL{
    display: none;
  }

}

.arrow-selected {
  transform: rotate(0);
}

// 英文样式
.locale-en {
  .option-item {
    a {
      @include mediaS {
        font-size: vm(16);
      }
      @include mediaM {
        font-size: 16px;
      }
    }
  }
}
