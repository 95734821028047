@import "~@/styles/viewport.scss";

.code {
  position: absolute;
  right: vm(16);
  top: 50%;
  padding-left: vm(20);
  transform: translate(0, -50%);
  color: #ff5353;
  cursor: pointer;
  user-select: none;
  text-align: center;
  white-space: nowrap;
}
