.register {
  .title {
    color: #1b1f4d;
    text-align: center;
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    // margin-bottom: 24px;
  }

  .loginBtn {
    border-radius: 28px;
    border: 1px solid #dfe0ed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-weight: 500;
    margin-top: 16px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: 28.8px;
      height: 28.8px;
      background-size: 28.8px 28.8px;
      position: absolute;
      left: 11.6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .phoneBtn {
    &::before {
      background-image: url('/images/login/phone.svg');
    }
  }

  .googleBtn {
    &::before {
      background-image: url('/images/login/google.svg');
    }
  }

  .appleBtn {
    &::before {
        background-image: url('/images/login/apple.svg');
    }
  }

  .googlContent {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    opacity: 0;
}

  .facebookBtn {
    &::before {
      background-image: url('/images/login/facebook.svg');
    }
  }

  .wechatBtn {
    &::before {
      background-image: url('/images/login/wechat.svg');
    }
  }

  .login {
    text-align: center;
    margin: 16px 0 24px 0;
    font-size: 14px;
  }

  .privacy {
    font-size: 14px;
    color: #8d8fa6;
    text-align: center;

    .highlight {
      color: #ff5353;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
