// 最粗 900
@font-face {
  font-family: 'HelveticaNeueLTPro';
  src: url('./fonts-origin/HelveticaNeueLTPro-Hv.woff2') format('woff2'),
    url('./fonts-origin/HelveticaNeueLTPro-Hv.woff') format('woff');
  font-weight: 900;
  font-display: optional;
}

// 细 700
@font-face {
  font-family: 'HelveticaNeueLTPro';
  src: url('./fonts-origin/HelveticaNeueLTPro-Md.woff2') format('woff2'),
    url('./fonts-origin/HelveticaNeueLTPro-Md.woff') format('woff');
  font-weight: 700;
  font-display: optional;
}
// 400 Linotype - HelveticaNeueLTPro-Roman.otf
@font-face {
  font-family: 'HelveticaNeueLTPro';
  src: url('./fonts-origin/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
    url('./fonts-origin/HelveticaNeueLTPro-Roman.woff') format('woff');
  font-weight: normal;
  font-display: optional;
}
