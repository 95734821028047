@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";

.container-footer {
  background: #fafafb;
  padding-top: 40px;

  @include mediaS {
    padding-top: vm(40);
  }
}

.crumbs {
  color: #999;
  font-size: 18px;
  margin-bottom: 20px;

  @include mediaS {
    font-size: vm(14);
    margin-bottom: vm(20);
    padding: 0 vm(10) 0 vm(40);
  }

  a {
    color: #333;
    text-decoration: none;
  }

  .arrow {
    padding: 0 12px;
    @include mediaS {
      padding: 0 vm(8);
    }
  }
}

.container {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 40px;

  @include mediaS {
    padding: 0;
    width: 100%;
  }
}

.footer-top {
  font-size: 14px;

  @include mediaL {
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.footerTopInner{
  // @include mediaS {
  //   display: flex;
  //   flex-direction: column-reverse;
  // }
}

.footer-top-title {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 32px;

  @include mediaS {
    font-size: vm(14);
    margin: vm(50) 0 vm(16) 0;
    text-align: center;
  }
}

.footer-top-social-media-inner {
  display: flex;

  @include mediaS {
    justify-content: center;
  }

  li {
    position: relative;
    float: left;

    a {
      display: inline-block;
      width: 48px;
      height: 48px;
      margin: 0 16px 0 0;

      &:last-child {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        transition: all 0.3s ease-in;
      }

      @include mediaS {
        width: vm(33);
        height: vm(33);
        margin: 0 vm(10) 0 0;
      }
    }

    &:hover a:last-child {
      opacity: 1;
    }

    &:last-child {
      a {
        margin: 0;
      }
    }
  }
}

.footer-top-call-box {
  // display: flex;
  margin-top: 41px;

  @include mediaS {
    width: vm(315);
    margin: vm(30) auto 0 auto;
  }

  position: relative;
  // justify-content: space-between;
}

.footer-top-call-box-l {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-top-call-box-r {
  position: absolute;

  @include mediaL {
    top: 0;
    left: 321px;
  }

  @include mediaS {
    top: 0;
    right: 0;
  }
}

.footer-top-call-box-qr-code {
  @include mediaS {
    width: vm(76);
    height: vm(76);
    border-radius: vm(10);
    border: vm(4) solid #ff6666;

    img {
      border-radius: vm(8);
    }
  }

  @include mediaL {
    width: 99px;
    height: 99px;
    border-radius: 10px;
    border: 4px solid #ff6666;

    img {
      border-radius: 9px;
    }
  }
}

.footer-top-call {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  &:nth-child(3), &:nth-child(1) {
    align-items: flex-start;
  }
  &:first-child{
    margin-bottom: 0;
  }
  @include mediaS {
    margin-bottom: vm(12);
    // justify-content: center;
    &:nth-child(2) {
      // margin-bottom: vm(10);
    }
  }
}

.footer-top-call-img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  padding: 8px;
  background: #EFEFF0;
  border-radius: 50%;
  @include mediaS {
    width: vm(16);
    height: vm(16);
  }
}

.call-info {
  margin-left: 20px;
  flex: 1;
  @include mediaS {
    margin-left: vm(6);
  }
  .tele{
    display: inline-block;
    padding: 0 0 8px;
  }
  p:first-child .tele{
    margin-bottom: 4px;
  }
}

.footer-top-nav-box {
  display: flex;

  @include mediaS {
    justify-content: space-evenly;
  }

  ul {
    li {
      min-width: 110px;
      border-left: 1px solid #e7e7e9;
      padding-left: 16px;

      // display: inline-block;
      @include mediaS {
        border-left: none;
        text-align: center;
        padding-left: 0;
      }

      a {
        color: #333;
        text-decoration: none;
        margin-bottom: 32px;
        display: inline-block;

        @include mediaS {
          margin-bottom: vm(30);
        }
      }

      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }

    &:nth-child(2) {
      margin-right: 50px;
      margin-left: 100px;

      @include mediaS {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.footer-bottom-pay {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 55px 0 49px 0;

  @include mediaS {
    margin: vm(20) 0 vm(12);
    white-space: pre-wrap;
  }

  p {
    font-size: 14px;

    @include mediaS {
      font-size: vm(10);

      &:nth-child(2) {
        margin-top: vm(6);
      }
    }

    > a {
      color: #333;
      text-decoration: underline;
    }
  }
}

.footer-bottom-pay-img {
  width: 786px;
  height: 40px;

  @include mediaS {
    width: vm(300);
    height: vm(76);
    align-self: center;
  }
}

.footer-logo {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @include mediaS {
    height: vm(88);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 120%;
    height: 110px;
    background: rgba(255, 83, 83, 0.2);
    transform: rotate(2.5deg);
    transform-origin: 0 0;

    @include mediaS {
      height: vm(76);
      transform: rotate(4deg);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 130px;
    background: #ff5353;
    transform: rotate(-2deg);
    transform-origin: 100% 100% 0;

    @include mediaS {
      height: vm(88);
      transform: rotate(-3deg);
    }
  }
}

.footer-logo-img {
  z-index: 1;
  width: 155px;
  height: 45px;
  margin-bottom: 20px;

  @include mediaS {
    width: vm(92);
    height: vm(30);
  }
}

.tele {
  color: #333;
  text-decoration: none;
}


.addressDomesticTitle {
  font-weight: 400;
  color: #333;
  @include mediaL {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  @include mediaS {
    font-size: vm(14);
    line-height:vm(20);
    margin-bottom: vm(6);
  }
}
.addressDomesticInfo {
  font-weight: 400;
  color: #666666;
  @include mediaL {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  @include mediaS {
    font-size: vm(12);
    line-height:vm(17);
    margin-bottom: vm(12);
  }
}

.locale-en {
}

.kidsafeLayout {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include mediaS {
    position: static;
    width: vm(260);
    height: vm(40);
  }
}

.kidsafeSection {
  width: 627.56px;
}

.kidsafeEmptySection {
  width: 454px;
}

.kidsafe {
  width: 150px;
  height: 52px;
  display: block;
  position: relative;
  @include mediaS {
    width: vm(72);
    height: vm(52);
  }
}
.kidsafeImg {
  width: 150px;
  height: 52px;
  @include mediaS {
    width: vm(72);
    height: vm(25);
  }
}

.kidsafeWrap {
  position: relative;
  display: flex;
  margin-bottom: 18px;
  align-items: center;
  justify-content: center;

  @include mediaS {
    flex-direction: column;
  }
}

.logo-link {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  position: absolute;
}

.navA{
  cursor: pointer;
}
