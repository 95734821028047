@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";

.label {
  position: relative;
  color: #333;
  font-weight: 600;
  @include mediaL {
    padding-left: 20px;
    font-size: 19px;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 18px;
      background: #ff5353;
      border-radius: 4px;
    }
  }
  @include mediaS {
    padding-left: vm(12);
    font-size: vm(15);
    margin-bottom: vm(10);
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: vm(6);
      height: vm(13);
      background: #ff5353;
      border-radius: vm(4);
    }
  }
}

.error {
  color: #ff5353;
  @include mediaL {
    font-size: 16px;
    margin-top: 6px;
    padding: 0 20px;
  }
  @include mediaS {
    font-size: vm(12);
    margin-top: vm(4);
    padding: 0 vm(16);
  }
}

.formItem {
  @include mediaL {
    margin-bottom: 28px;
  }
  @include mediaS {
    margin-bottom: vm(28);
  }
}
