.toastContainer {
  position: fixed;
  z-index: 99999999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.toast {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 11px 18px;
  border-radius: 6px;
  pointer-events: none;
  display: flex;
  align-items: center;
  line-height: 1.4;
  max-width: 243px;
}

.success {
  color: #00ac5b;
}

.icon {
  margin-right: 8px;
  svg,
  img {
    vertical-align: middle;
  }
}
