.registSuccess {
  .title {
    color: #1B1F4D;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .subTitle {
    color: #484C70;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .img {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 40px;
  }
}

