@import '~@/styles/viewport.scss';
@import '~@/styles/mediaQuery.scss';


.voiceModal {
  // top: 200px;
  font-size: 16px;
  :global(.rc-dialog-content) {
    border-radius: 12px;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #1b1f4d;
    margin-bottom: 24px;
    padding-top: 28px;
    padding: 28px 44px 0 44px;
  }

  .returnImg {
    position: absolute;
    left: 16px;
    top: 16px;
    cursor: pointer;
  }

  .closeImg {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

  .subTitle {
    color: #484c70;
    text-align: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    padding: 0 44px;
  }

  .countryCode {
    height: 48px;
    font-size: 16px;
    > div > div {
      background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3)) !important;
    }
  }

  .formItemErr {
    padding: 0 20px;
    font-size: 16px;
  }

  .input {
    height: 48px;
    border: 1px solid #dfe0ed;
    border-radius: 24px;
    background-color: #fff;
    font-size: 16px;
    padding: 0 20px;
  }

  .phoneInput {
    @extend .input;
    padding: 0 30px 0 126px;
    background-color: rgba(247, 247, 247, 1);
  }

  .form {
    padding: 0 44px;
  }

  .verifyCode {
    background: #fff;
    color: #ff6b00;
    cursor: pointer;
    font-weight: 400;

    border-radius: 0 vwPC(50) vwPC(50) 0;
    padding-right: vwPC(5);
    white-space: nowrap;
    font-size: 16px !important;
    height: 46px;
    box-shadow: none;
    // &:disabled {
    //   background: #f7f7f7;
    // }
  }

  .verifyCodeContainer {
    position: absolute;
    display: inline-block;
    top: 1px;
    right: 20px;
  }

  .code {
    position: relative;
  }

  .fixFormItem {
    margin-bottom: 24px;
  }

  .submitBtn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    margin-bottom: 44px;
    margin-top: 40px;
    box-shadow: none;
  }

  :global {
    .rc-dialog-close {
      display: none;
      top: 16px;
      right: 16px;
      font-weight: 400;
      font-size: 24px;
    }

    .rc-dialog-body {
      position: relative;
    }
  }
}
