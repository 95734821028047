@import "~@/styles/mediaQuery.scss";
@import '~@/styles/viewport.scss';

.mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  z-index: 999999999999;
}
.hide {
  display: none;
}

.container {
  position: relative;
  top: 60px;
  width: 460px;
  height: 511px;
  box-sizing: border-box;
  background: #FF5353;
  border-radius: 12px;
  padding: 10px;
  animation: identifier 1.5s;
  @include mediaXL {
    width: 520px;
    height: 511px;
  }
  @include mediaS {
    width: 335px;
    height: 448px;
  }
}

.content {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 12px;
}
.contentTips {
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.003em;
  color: rgba(0, 0, 0, 0.85);
  width: 340px;
  margin: auto;
  padding-top: 75px;
  :global {
    .time {
      color: #ff5353;
      font-weight: 800;
      position: relative;
      z-index: 2001;
      &::after {
        content: '';
        position: absolute;
        width: 93px;
        height: 10px;
        margin: 18px 0 0 -90px;
        background: rgba(255, 212, 57, 0.2);
        border-radius: 40px;
        z-index: 2;
        @include mediaS {
          display: none;
        }
      }
    }
    .time-write {
      font-weight: 400;
      font-size: 18px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.003em;
      color: rgba(0, 0, 0, 0.85);
      @include mediaS {
        font-size: 14px;
      }
    }
  }
  @include mediaS {
    font-size: 15px;
    width: 80%;
    padding-top: 60px;
  }
}
.contentTipsRed {
  color: #ff5353;
  &::after {
    content: '';
    position: absolute;
    width: 93px;
    height: 10px;
    margin: 18px 0 0 -90px;
    background: rgba(255, 212, 57, 0.2);
    border-radius: 40px;
    @include mediaS {
      display: none;
    }
  }
}

.containerTitleBg {
  width: 500px;
  height: 163px;
  position: absolute;
  top: -110px;
  left: -4%;
  @include mediaXL {
    width: 564px;
    height: 187px;
    position: absolute;
    top: -124px;
    left: -4.5%;
  }
  @include mediaS {
    width: 372px;
    height: 123px;
    position: absolute;
    top: -75px;
    left: -5%;
  }
}
.qrCodeContent {
  width: 200px;
  height: 200px;
  margin: 22px auto 36px auto;
  background-image: url('./assets/qrcode-bg.png');
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mediaXL {
    margin: 32px auto 36px auto;
  }
  @include mediaS {
    width: 181px;
    height: 181px;
    margin: 26px auto 26px auto;
  }
}
.ercode {
  width: 134px;
  height: 134px;
}
.bottomTips {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.45);
  @include mediaS {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.75);
  }
  span {
    margin: 0 6px;
  }
}
.closeImg {
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  left: 95%;
  top: -105px;
  z-index: 2;
  @include mediaS {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 90%;
    top: -80px;
  }
}

.locale-en {
  .container {
    position: relative;
    width: 460px;
    height: 528px;
    box-sizing: border-box;
    background: #FF5353;
    border-radius: 12px;
    padding: 10px;
    animation: identifier 1.5s;
    @include mediaXL {
      width: 520px;
      height: 561px;
    }
    @include mediaS {
      width: 335px;
      height: 448px;
    }
  }
  .contentTips {
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.003em;
    color: rgba(0, 0, 0, 0.85);
    width: 380px;
    padding-top: 45px;
    @include mediaXL {
      font-weight: 800;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.003em;
      color: rgba(0, 0, 0, 0.85);
      width: 380px;
      padding-top: 75px;
    }
    @include mediaS {
      font-size: 16px;
      padding-top: 50px;
      width: 85%;
      line-height: 24px;
    }
    :global {
      .time {
        color: #ff5353;
        font-weight: 800;
        &::after {
          content: '';
          position: absolute;
          width: 93px;
          height: 10px;
          margin: 18px 0 0 -90px;
          background: rgba(255, 212, 57, 0.2);
          border-radius: 40px;
          @include mediaS {
            display: none;
          }
        }
      }
      .time-write {
        font-weight: 400;
        font-size: 18px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.003em;
        color: rgba(0, 0, 0, 0.85);
        padding-top: 10px;
        @include mediaS {
          font-size: 14px;
        }
      }
      .time-write-b {
        @include mediaS {
          position: relative;
          bottom: 4px;
        }
      }
    }
  }
  .qrCodeContent {
    @include mediaS {
      margin: 18px auto 18px auto;
    }
  }
  .ercode {
    @include mediaS {
      width: 143px;
      height: 143px;
    }
  }
  .bottomTips {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 0;
    width: 80%;
    margin: auto;
    @include mediaS {
      line-height: 18px;
      font-size: 12px;
    }
  }
}
@keyframes identifier {
  0% {
    transform: translateY(400px);
  }
  100% {
    transform: translateY(0px);
  }
}
