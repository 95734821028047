@import "~@/styles/viewport.scss";

.phone-input-wrap {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.country-code {
  position: absolute;
  display: flex;
  align-items: center;
  width: vm(72);
  height: 100%;
  justify-content: flex-end;
  top: 50%;
  transform: translateY(-50%);
  -webkit-tap-highlight-color: transparent;

  span {
    width: vm(68);
    text-align: right;
    display: inline-block;
    padding-left: vm(10);
    overflow: hidden;
    margin-right: vm(2);
  }
}

.phone-input {
  input {
    padding-left: vm(90);
  }
}

.arrow {
  vertical-align: middle;
  margin-left: vm(8);
  width: vm(12);
  height: vm(6);
  transition: transform 0.3s;

  &.open {
    transform: rotate(180deg);
  }
}
